var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessWithoutReload", {
        attrs: { api: this.api },
        on: {
          close: function () {
            this$1.$router.go(-1)
          },
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "12", cols: "12", md: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "px-3", attrs: { flat: "" } },
                [
                  _c(
                    "v-row",
                    {},
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 mt-0",
                          attrs: { cols: "12", lg: "12", md: "12" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-4",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function () {
                                      this$1.$emit("returnBack")
                                    },
                                  },
                                },
                                [_vm._v(" Go back ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start mb-2" },
                            [
                              _c(
                                "span",
                                { staticClass: "text-h6 font-weight-bold" },
                                [_vm._v(" Invoice details ")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  dense: "",
                                  "item-value": "company_id",
                                  "item-text": "company_name",
                                  items: _vm.companyOptions,
                                  loading: _vm.isLoadingCompany,
                                  outlined: "",
                                  required: "",
                                  label: "Company (required)",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(item.company_id) +
                                              " - " +
                                              _vm._s(item.company_name) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.data.company_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "company_id", $$v)
                                  },
                                  expression: "data.company_id",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Invoice no",
                                  dense: "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.invoice_no,
                                  callback: function ($$v) {
                                    _vm.invoice_no = $$v
                                  },
                                  expression: "invoice_no",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Client no",
                                  dense: "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.client_no,
                                  callback: function ($$v) {
                                    _vm.client_no = $$v
                                  },
                                  expression: "client_no",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("ComponentDateModal", {
                            attrs: {
                              label: "Issued date",
                              data: _vm.issued_at,
                            },
                            on: {
                              updateDateData: function (data) {
                                this$1.issued_at = data
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: "Description (required)",
                                  dense: "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.data.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "description", $$v)
                                  },
                                  expression: "data.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Subtotal ( RM )",
                                  dense: "",
                                  outlined: "",
                                },
                                model: {
                                  value: _vm.subtotal,
                                  callback: function ($$v) {
                                    _vm.subtotal = $$v
                                  },
                                  expression: "subtotal",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  label: "Tax Amount ( RM )",
                                },
                                model: {
                                  value: _vm.tax_amount,
                                  callback: function ($$v) {
                                    _vm.tax_amount = $$v
                                  },
                                  expression: "tax_amount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  label: "Total ( RM )",
                                },
                                model: {
                                  value: _vm.total,
                                  callback: function ($$v) {
                                    _vm.total = $$v
                                  },
                                  expression: "total",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-start" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  label: "Amount due ( RM )",
                                },
                                model: {
                                  value: _vm.amount_due,
                                  callback: function ($$v) {
                                    _vm.amount_due = $$v
                                  },
                                  expression: "amount_due",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", text: "" },
                                  on: {
                                    click: function () {
                                      _vm.$router.go(-1)
                                    },
                                  },
                                },
                                [_vm._v(" Cancel ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.api.isLoading,
                                  },
                                  on: { click: _vm.validateInput },
                                },
                                [_vm._v(" Confirm ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-end" },
                            [_c("AError", { attrs: { api: this.api } })],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }